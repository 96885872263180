/* eslint-disable react-hooks/exhaustive-deps */
import "./App.css";
import logo from "./mkis.webp";
import e10 from "./e10.webp";
import { useState, useEffect } from "react";
import axios from "axios";
import Cookies from "js-cookie";
import Select from "react-select";
import SidebarComponent from "./SidebarComponent";
import DialogComponent from "./DialogComponent";

function App() {
  const [isMounted, setIsMounted] = useState(false);
  const [isMountedSideBar, setIsMountedSideBar] = useState(
    !Cookies.get("seenCookiePolicy")
  );
  const [selectedBrand, setSelectedBrand] = useState(null);
  const [selectedModel, setSelectedModel] = useState(null);
  const [selectedYear, setSelectedYear] = useState(null);
  const [confirmation, setConfirmation] = useState(false);
  const [results, setResults] = useState([]);
  const [visible, setVisible] = useState(false);
  const [visibleReg, setVisibleReg] = useState(false);
  const [visiblePolicy, setVisiblePolicy] = useState(false);
  const [visibleRODO, setVisibleRODO] = useState(false);
  const [visibleAP, setVisibleAP] = useState(false);

  const [brands, setBrands] = useState([]);
  const [models, setModels] = useState([]);
  const [years, setYears] = useState([]);
  const [visibleBottom, setVisibleBottom] = useState(
    !Cookies.get("seenCookiePolicy")
  );
  const apiUrl = process.env.REACT_APP_API_URL;

  useEffect(() => {
    document.getElementById("brand").removeAttribute("autocorrect");
    document.getElementById("model").removeAttribute("autocorrect");
    document.getElementById("year").removeAttribute("autocorrect");
    axios.get(`${apiUrl}/api/e10/brands`).then((res) => {
      const options = res.data.map((brandObj) => ({
        value: brandObj.brand,
        label: brandObj.brand,
      }));
      setBrands(options);
    });
  }, []);

  useEffect(() => {
    if (selectedBrand !== null && models.length === 0) {
      axios
        .get(
          `${apiUrl}/api/e10/brands/${selectedBrand.value.replace(
            "/",
            "%2F"
          )}/models`
        )
        .then((res) => {
          const options = res.data.map((modelObj) => ({
            value: modelObj.model,
            label: modelObj.model,
          }));
          setModels(options);
        });
    }
  }, [selectedBrand]);

  useEffect(() => {
    if (selectedModel !== null && years.length === 0) {
      axios
        .get(
          `${apiUrl}/api/e10/brands/${selectedBrand.value}/models/${selectedModel.value}/years`
        )
        .then((res) => {
          const numberArray = generateYearRanges(res.data);
          setYears(numberArray);
        });
    }
  }, [selectedModel]);

  const handleBrandChange = (e) => {
    if (e !== selectedBrand) {
      setSelectedBrand(e);
      setSelectedModel(null);
      setModels([]);
      setSelectedYear(null);
      setYears([]);
      setConfirmation(false);
    }
  };

  const handleModelChange = (e) => {
    if (e !== selectedModel) {
      setSelectedModel(e);
      setSelectedYear(null);
      setConfirmation(false);
      setYears([]);
    }
  };

  const handleYearChange = (e) => {
    setSelectedYear(e);
    setConfirmation(false);
  };

  const onHide = () => {
    setIsMountedSideBar(!isMountedSideBar);
    if (!visibleBottom) setVisibleBottom(true);
    Cookies.set("seenCookiePolicy", "true", { expires: 365 });
  };

  const generateYearRanges = (data) => {
    const yearRanges = [];

    for (const item of data) {
      const startYear = item.manufacture_year_since;
      const endYear = item.manufacture_year_until;

      if (startYear <= endYear) {
        for (let year = startYear; year <= endYear; year++) {
          yearRanges.push({ value: year.toString(), label: year.toString() });
        }
      }
    }
    const uniqueOptions = yearRanges.filter(
      (option, index, self) =>
        index === self.findIndex((o) => o.value === option.value)
    );

    return uniqueOptions;
  };

  const handleCheckboxChange = () => {
    setConfirmation(!confirmation);
  };

  const handleSubmit = (event) => {
    if (selectedYear && confirmation) {
      event.preventDefault();
      axios
        .get(
          `${apiUrl}/api/e10/brands/${selectedBrand.value}/models/${selectedModel.value}/year/${selectedYear.value}`
        )
        .then((res) => {
          const groupedData = res.data.reduce((groups, item) => {
            if (!groups[item.generation]) {
              groups[item.generation] = [];
            }
            groups[item.generation].push(item);
            return groups;
          }, {});
          setResults(groupedData);
          setIsMounted(!isMounted);
          if (!visible) setVisible(true);
        });
    } else {
      event.preventDefault();
    }
  };

  return (
    <div className="container-lg flexbox">
      <header className="header">
        <div>
          <div className="d-flex flex-wrap align-items-center justify-content-lg-start">
            <a
              href="https://www.gov.pl/web/klimat"
              className="d-flex align-items-center mb-2 mb-lg-0 text-dark text-decoration-none"
              title="Link do strony Ministerstwa Klimatu i Środowiska"
            >
              <img
                className="logo"
                src={logo}
                alt="Logo Ministerstwa Klimatu i Środowiska"
              />
            </a>
          </div>
        </div>
      </header>
      <div>
        <div>
          <h1 className="title">
            Sprawdź czy twój pojazd <br /> jest kompatybilny z benzyną E10
          </h1>
          <h2 className="subtitle">
            Proszę wypełnić poniższe informacje i kliknąć przycisk "SPRAWDŹ"
          </h2>
        </div>
        <form onSubmit={handleSubmit}>
          <div className="d-flex">
            <div className="left-panel">
              <div className="input-container">
                <label htmlFor="brand" className="left-panel-label">
                  Marka pojazdu
                </label>
                <Select
                  inputId="brand"
                  aria-label="Autouzupełnianie z listą, wybierz markę auta"
                  className="basic-single"
                  classNamePrefix="select"
                  value={selectedBrand}
                  isSearchable={true}
                  options={brands}
                  noOptionsMessage={() => "Brak wyników"}
                  placeholder="Wybierz markę pojazdu"
                  onChange={handleBrandChange}
                />
              </div>
              <div className="input-container">
                <label htmlFor="model" className="left-panel-label">
                  Model
                </label>
                <Select
                  inputId="model"
                  aria-label="Autouzupełnianie z listą, wybierz model auta"
                  className="basic-single"
                  classNamePrefix="select"
                  value={selectedModel}
                  isSearchable={true}
                  options={models}
                  onChange={handleModelChange}
                  noOptionsMessage={() => "Brak wyników"}
                  placeholder="Wybierz model pojazdu"
                  isDisabled={selectedBrand === null}
                />
              </div>
              <div className="input-container">
                <label htmlFor="year" className="left-panel-label">
                  Rok
                </label>
                <Select
                  inputId="year"
                  aria-label="Autouzupełnianie z listą, wybierz rok produkcji auta"
                  className="basic-single"
                  classNamePrefix="select"
                  isDisabled={selectedModel === null}
                  value={selectedYear}
                  isSearchable={true}
                  options={years}
                  noOptionsMessage={() => "Brak wyników"}
                  placeholder="Wybierz rok produkcji pojazdu"
                  onChange={handleYearChange}
                />
              </div>
              <div className="input-container">
                {/* <div className="d-flex align-items-center">
                    <Checkbox
                      inputId="confirm"
                      onChange={(e) => setConfirmation(e.checked)}
                      checked={confirmation}
                    />
                    <label htmlFor="confirm" className="checkbox-label">
                      Potwierdzam, że mój pojazd korzysta z benzyny (nie oleju
                      napędowego)
                    </label>
                  </div> */}
                <div className="d-flex align-items-center">
                  <label className="container-checkbox">
                    Potwierdzam, że mój pojazd korzysta z benzyny (nie oleju
                    napędowego)
                    <input
                      type="checkbox"
                      onChange={handleCheckboxChange}
                      checked={confirmation}
                    />
                    <span className="checkmark"></span>
                  </label>
                </div>
              </div>
              <button
                id="button-submit"
                type="submit"
                className={
                  selectedYear === null || !confirmation
                    ? "submit-button-disabled"
                    : "submit-button"
                }
              >
                SPRAWDŹ
              </button>
            </div>
            <div>
              <img
                src={e10}
                alt="Ilustracja ozdobna przedstawiająca dystrybutor z logiem e10"
                className="right-image"
              />
            </div>
          </div>
        </form>
        <div className="alert info-container">
          <div className="info-margin">
            <strong className="text-danger">UWAGA:</strong> Jeśli Twój samochód
            nie znajduje się w wyszukiwarce pojazdów:
            <ul>
              <li>Zajrzyj do wnętrza klapy wlewu paliwa.</li>
              <li>Sprawdź instrukcję obsługi pojazdu.</li>
              <li>Zapytaj producenta samochodu/lokalnego dealera.</li>
            </ul>
            <p>
              Jeśli nadal nie masz pewności, użyj benzyny E5. Nadal będzie
              dostępna na stacjach benzynowych jako benzyna 98-oktanowa oraz
              100-oktanowa.
            </p>
          </div>
          <i className="bi bi-info-circle info-icon tooltip-info">
            <span className="tooltiptext">
              Wszelkie nieprawidłowości stwierdzone w trakcie korzystania z
              Wyszukiwarki proszę zgłaszać na adres mailowy: drp@klimat.gov.pl
            </span>
          </i>
        </div>
      </div>

      <footer className="footer">
        <p>
          Ministerstwo Klimatu i Środowiska dochowało należytej staranności w
          zebraniu danych zamieszczonych w wyszukiwarce, a dane te pochodzą od
          opracowań publicznie/ogólnie dostępnych. Skarb Państwa – Ministerstwo
          Klimatu i Środowiska nie ponosi odpowiedzialności za ewentualne błędy
          informacji zawartych w wyszukiwarce oraz za konsekwencje tych błędów.
          W szczególności Skarb Państwa – Ministerstwo Klimatu i Środowiska nie
          ponosi odpowiedzialności za jakiekolwiek szkody lub krzywdy będące
          rezultatem zastosowania się do informacji zawartych w wyszukiwarce.
          Wszelkie ryzyko związane z wykorzystywaniem przedmiotowych informacji
          ponosi użytkownik pojazdu, który w razie wątpliwości w zakresie
          kompatybilności danego pojazdu z benzyną E10 powinien zwrócić się w
          tej sprawie do producenta pojazdu.
        </p>
        <div className="footer-link-container">
          <button
            id="button-reg"
            className="footer-link footer_button"
            onClick={() => {
              setIsMounted(!isMounted);
              if (!visibleReg) setVisibleReg(true);
            }}
          >
            Regulamin
          </button>
          <button
            id="button-policy"
            className="footer-link footer_button"
            onClick={() => {
              setIsMounted(!isMounted);
              if (!visiblePolicy) setVisiblePolicy(true);
            }}
          >
            Polityka prywatności
          </button>
          <button
            id="button-RODO"
            className="footer-link footer_button"
            onClick={() => {
              setIsMounted(!isMounted);
              if (!visibleRODO) setVisibleRODO(true);
            }}
          >
            Klauzula RODO
          </button>
          <button
            id="button-accessibility-policy"
            className="footer-link footer_button"
            onClick={() => {
              setIsMounted(!isMounted);
              if (!visibleAP) setVisibleAP(true);
            }}
          >
            Deklaracja dostępności
          </button>
        </div>
      </footer>

      <DialogComponent
        lastFocusedElement="button-submit"
        visible={visible}
        isMounted={isMounted}
        headerContent={
          <dl className="d-flex mb-0 results-dialog-header">
            <span>
              <dt className="me-1">Marka: </dt>
              <dd className="me-3">{selectedBrand?.value}</dd>
            </span>
            <span>
              <dt className="me-1">Model: </dt>
              <dd className="me-3">{selectedModel?.value}</dd>
            </span>
            <span>
              <dt className="me-1">Rok: </dt>
              <dd>{selectedYear?.value}</dd>
            </span>
          </dl>
        }
        children={Object.keys(results).map((generation, index) => (
          <div key={index}>
            {generation !== "null" ? (
              <p>
                <strong>Generacja:</strong> {generation}
              </p>
            ) : (
              ""
            )}
            Rodzaj silnika:
            <ul>
              {results[generation].map((item, index) => (
                <li key={index}>
                  {item.engine_type} -{" "}
                  {item.e10_compliance === -1 && (
                    <strong className="no-data">
                      Brak informacji czy Twój pojazd jest zgodny z E10. Aby
                      uzyskać informację zajrzyj do wnętrza klapy wlewu paliwa
                      lub sprawdź instrukcję obsługi pojazdu lub zapytaj
                      producenta samochodu/lokalnego dealera.
                    </strong>
                  )}
                  {item.e10_compliance === 1 && (
                    <strong className="positive">możesz tankować E10</strong>
                  )}
                  {item.e10_compliance === 0 && (
                    <strong className="negative">
                      nie możesz tankować E10
                    </strong>
                  )}
                  {item.message !== null ? (
                    <div
                      className={
                        item.e10_compliance === 1
                          ? "positive"
                          : item.e10_compliance === 0
                          ? "negative"
                          : "no-data"
                      }
                    >
                      {item.message}
                    </div>
                  ) : (
                    ""
                  )}
                </li>
              ))}
            </ul>
          </div>
        ))}
        onClose={() => {
          setIsMounted(!isMounted);
          if (!visible) setVisible(true);
        }}
        setShow={setVisible}
      />

      <DialogComponent
        lastFocusedElement="button-reg"
        visible={visibleReg}
        isMounted={isMounted}
        headerContent={<h2>Regulamin Wyszukiwarki</h2>}
        children={
          <div className="m-0">
            <strong>1. Postanowienia ogólne</strong>
            <ol>
              <li>
                Niniejszy Regulamin określa zasady funkcjonowania oraz warunki
                korzystania z „Wyszukiwarki internetowej dotyczącej
                kompatybilności samochodów osobowych z benzyną E10”, zwanej
                dalej Wyszukiwarką. Regulamin definiuje prawa i obowiązki
                korzystających z Wyszukiwarki Użytkowników oraz administratora
                Wyszukiwarki w zakresie świadczenia usług drogą elektroniczną.
              </li>
              <li>
                „Wyszukiwarka” oznacza witrynę internetową znajdującą się pod
                adresem e10.klimat.gov.pl.
              </li>
              <li>
                „Administratorem” Wyszukiwarki jest Skarb Państwa – Ministerstwo
                Klimatu i Środowiska, 00-922 Warszawa, ul. Wawelska 52/54, NIP
                526-16-47-453; REGON 1064858.
              </li>
              <li>
                „Użytkownikiem” jest każda osoba, która odwiedza witrynę
                Wyszukiwarki.
              </li>
              <li>
                Administrator zastrzega sobie prawo do ograniczenia dostępu do
                niektórych treści lub funkcji Wyszukiwarki wedle przyjętych
                przez siebie kryteriów we wskazanych przez siebie
                okolicznościach.
              </li>
              <li>
                Administrator zastrzega sobie prawo do dokonywania zmian w
                treści Regulaminu, jak również wprowadzenia nowego regulaminu, w
                szczególności, jeśli zmiany te spowodowane będą okolicznościami
                faktycznymi lub prawnymi.
              </li>
              <li>
                Jednocześnie Administrator zastrzega sobie prawo do dokonywania
                wszelkich zmian w Regulaminie bez informowania o tym
                Użytkownika.
              </li>
              <li>
                Prawem właściwym dla wszelkich stosunków prawnych wynikających z
                niniejszego Regulaminu jest prawo polskie. Spory rozstrzygane
                będą przez polskie sądy właściwe dla danej sprawy.
              </li>
            </ol>
            <strong>2. Warunki techniczne korzystania z Wyszukiwarki </strong>
            <ol>
              <li>
                Do korzystania z Wyszukiwarki niezbędne jest posiadanie
                urządzenia elektronicznego (np. komputer, telefon, tablet,
                telewizor i inne) wyposażonego w możliwie aktualną wersję
                przeglądarki internetowej. Administrator nie ponosi
                odpowiedzialności, jeżeli urządzenie Użytkownika nie jest w
                stanie prawidłowo emitować treści.
              </li>
            </ol>
            <strong>3. Instrukcja korzystania z Wyszukiwarki</strong>
            <ol>
              <li>
                W pierwszym polu wyboru „Marka pojazdu”, Użytkownik wybiera/lub
                wpisuje markę samochodu osobowego.
              </li>
              <li>
                W następnym kroku z drugiego pola „Model” Użytkownik wybiera/lub
                wpisuje model, marki samochodu osobowego wybranego w punkcie 1.
              </li>
              <li>
                W kolejnym polu wyboru „Rok” Użytkownik wybiera rok produkcji
                danego modelu samochodu.
              </li>
              <li>
                Po dokonaniu wyboru oraz zaznaczeniu pola „Potwierdzam, że mój
                pojazd korzysta z benzyny (nie oleju napędowego)” wyświetli się
                informacja prezentująca wszystkie rodzaje silnika dla wybranego
                samochodu osobowego wraz z informacją, czy dany rodzaj silnika
                jest przystosowany lub nieprzystosowany do stosowania paliwa ze
                zwiększoną zawartością biokomponentów (E10). W przypadku, gdy w
                danym roku produkowana jest więcej niż jedna generacja danego
                modelu samochodu, wówczas prezentowane na stronie są wszystkie
                rodzaje silników dla tej generacji.
              </li>
            </ol>
            <strong>4. Prawo autorskie</strong>
            <ol>
              <li>
                Wszelkie treści opublikowane w ramach Wyszukiwarki podlegają
                ochronie Prawno autorskiej. Prawa autorskie przysługują
                Wyszukiwarce i/lub twórcom.
              </li>
              <li>
                Zabronione jest kopiowanie i rozpowszechnianie treści
                Wyszukiwarki w zakresie przekraczającym tzw. dozwolony użytek
                zdefiniowany przez ustawę o prawie autorskim i prawach
                pokrewnych z dnia 4 lutego 1994 roku.
              </li>
              <li>Dozwolone jest linkowanie bezpośrednio do Wyszukiwarki. </li>
            </ol>
            <strong>5. Prawa i obowiązki użytkownika </strong>
            <ol>
              <li>
                Internauta nabywa status Użytkownika wraz z uruchomieniem w
                przeglądarce internetowej strony Wyszukiwarki.
              </li>
              <li>
                Korzystający z Wyszukiwarki Użytkownicy zobowiązani są do
                przestrzegania niniejszego Regulaminu oraz przepisów polskiego
                prawa.
              </li>
              <li>
                Użytkownik ma prawo do korzystania z Wyszukiwarki poprzez
                przeglądanie i wyszukiwanie zasobów z wyłączeniem treści, do
                których dostęp Administrator ogranicza wedle przyjętych przez
                siebie kryteriów.
              </li>
              <li>
                Użytkownik zobowiązuje się do powstrzymania od wszelkich
                praktyk, które mogłyby narazić techniczne i merytoryczne aspekty
                funkcjonowania Wyszukiwarki, w szczególności od spamowania,
                nieuzgodnionych działań reklamowych, zachowań, które mogą zostać
                uznane za celowe próby destabilizacji technicznego zaplecza
                Wyszukiwarki (ataki DDoS).
              </li>
              <li>
                W przypadku łamania przepisów prawa przez Użytkowników lub
                działania na szkodę Wyszukiwarki, Administrator zastrzega sobie
                prawo do podjęcia stosownych działań prawnych, jak również
                udostępniania niezbędnych danych zainteresowanym organom
                ścigania.
              </li>
            </ol>
            <strong>6. Dane osobowe i ochrona prywatności</strong>
            <ol>
              <li>
                Wyszukiwarka nie przetwarza tj. nie zbiera, nie utrwala, nie
                organizuje, nie porządkuje, nie przechowuje, nie adaptuje lub
                nie modyfikuje, nie pobiera, nie przegląda, nie wykorzystuje,
                nie ujawnia poprzez przesłanie, nie rozpowszechnia lub w inny
                sposób nie udostępnia, nie dopasowuje lub nie łączy, nie
                ogranicza, nie usuwa lub nie niszczy danych osobowych
                Użytkownika zgodnie z definicja art. 4 ust. 2 rozporządzenia
                Parlamentu Europejskiego i Rady (UE) 2016/679 z dnia 27 kwietnia
                2016 r. w sprawie ochrony osób fizycznych w związku z
                przetwarzaniem danych osobowych i w sprawie swobodnego przepływu
                takich danych oraz uchylenia dyrektywy 95/46/WE (ogólne
                rozporządzenie o ochronie danych) (Dz. Urz. UE L 119 z
                04.05.2016, str. 1, z późn. zm.).
              </li>
            </ol>
            <strong>7. Wyłączenie odpowiedzialności</strong>
            <ol>
              <li>
                Administrator zastrzega sobie prawo do przerw i zakłóceń w
                działaniu witryny, wprowadzaniu ograniczeń terytorialnych lub
                wiekowych w dostępie, jak również zamknięcia Wyszukiwarki bez
                uprzedniego zawiadomienia.
              </li>
              <li>
                Administrator nie ponosi odpowiedzialności za działania
                Użytkowników i osób trzecich w zakresie naruszania prawa na
                łamach Wyszukiwarki. Pełną i osobistą odpowiedzialność w tym
                zakresie ponosi Użytkownik.
              </li>
            </ol>
            <strong>8. Postanowienia końcowe</strong>
            <ol>
              <li>
                Niniejszy Regulamin zaczyna obowiązywać z chwilą ogłoszenia go w
                serwisie.
              </li>
              <li>
                Data ostatniej aktualizacji regulaminu: 16 listopada 2023 roku.
              </li>
              <li>
                Korzystanie z Wyszukiwarki przez Użytkownika jest równoznaczne z
                akceptacją Regulaminu.
              </li>
              <li>
                Wszelkie nieprawidłowości stwierdzone w trakcie korzystania z
                Wyszukiwarki proszę zgłaszać na adres mailowy:
                drp@klimat.gov.pl.
              </li>
            </ol>
          </div>
        }
        onClose={() => {
          setIsMounted(!isMounted);
          if (!visibleReg) setVisibleReg(true);
        }}
        setShow={setVisibleReg}
      />

      <DialogComponent
        lastFocusedElement="button-policy"
        visible={visiblePolicy}
        isMounted={isMounted}
        headerContent={<h2>Polityka Prywatności</h2>}
        children={
          <p className="m-0">
            Uprzejmie informuję, że nie przetwarzamy, tj. nie zbieramy, nie
            utrwalamy, nie organizujemy, nie porządkujemy, nie przechowujemy,
            nie adaptujemy lub nie modyfikujemy, nie pobieramy, nie przeglądamy,
            nie wykorzystujemy, nie ujawniamy poprzez przesłanie, nie
            rozpowszechniamy lub w inny sposób nie udostępniamy, nie
            dopasowujemy lub nie łączymy, nie ograniczamy, nie usuwamy lub nie
            niszczymy, Twoich danych osobowych, zgodnie z definicja art. 4 ust.
            2 rozporządzenia Parlamentu Europejskiego i Rady (UE) 2016/679 z
            dnia 27 kwietnia 2016 r. w sprawie ochrony osób fizycznych w związku
            z przetwarzaniem danych osobowych i w sprawie swobodnego przepływu
            takich danych oraz uchylenia dyrektywy 95/46/WE (ogólne
            rozporządzenie o ochronie danych) (Dz. Urz. UE L 119 z 04.05.2016,
            str. 1, z późn. zm.).
          </p>
        }
        onClose={() => {
          setIsMounted(!isMounted);
          if (!visiblePolicy) setVisiblePolicy(true);
        }}
        setShow={setVisiblePolicy}
      />
      <DialogComponent
        lastFocusedElement="button-RODO"
        visible={visibleRODO}
        isMounted={isMounted}
        headerContent={<h2>Klauzula RODO</h2>}
        children={
          <p className="m-0">
            Uprzejmie informuję, że nie przetwarzamy, tj. nie zbieramy, nie
            utrwalamy, nie organizujemy, nie porządkujemy, nie przechowujemy,
            nie adaptujemy lub nie modyfikujemy, nie pobieramy, nie przeglądamy,
            nie wykorzystujemy, nie ujawniamy poprzez przesłanie, nie
            rozpowszechniamy lub w inny sposób nie udostępniamy, nie
            dopasowujemy lub nie łączymy, nie ograniczamy, nie usuwamy lub nie
            niszczymy, Twoich danych osobowych, zgodnie z definicja art. 4 ust.
            2 rozporządzenia Parlamentu Europejskiego i Rady (UE) 2016/679 z
            dnia 27 kwietnia 2016 r. w sprawie ochrony osób fizycznych w związku
            z przetwarzaniem danych osobowych i w sprawie swobodnego przepływu
            takich danych oraz uchylenia dyrektywy 95/46/WE (ogólne
            rozporządzenie o ochronie danych) (Dz. Urz. UE L 119 z 04.05.2016,
            str. 1, z późn. zm.).
          </p>
        }
        onClose={() => {
          setIsMounted(!isMounted);
          if (!visibleRODO) setVisibleRODO(true);
        }}
        setShow={setVisibleRODO}
      />

      <DialogComponent
        lastFocusedElement="button-accessibility-policy"
        visible={visibleAP}
        isMounted={isMounted}
        headerContent={<h2>Deklaracja dostępności</h2>}
        children={
          <div className="m-0">
            <p>
              <strong>
                Ministerstwo Klimatu i Środowiska zobowiązuje się zapewnić
                dostępność strony internetowej gov.pl zgodnie z ustawą z dnia 4
                kwietnia 2019 r. o dostępności cyfrowej stron internetowych i
                aplikacji mobilnych podmiotów publicznych. Oświadczenie w
                sprawie dostępności ma zastosowanie do serwisu E10.klimat.gov.pl
              </strong>
            </p>
            <p>Data publikacji strony internetowej: 16.11.2023 r.</p>
            <p>Data ostatniej istotnej aktualizacji: 16.12.2023 r.</p>
            <h4>Status dostępności</h4>
            <p>
              <strong>
                Strona internetowa jest zgodna z ustawą z dnia 4 kwietnia 2019
                r. o dostępności cyfrowej stron internetowych i aplikacji
                mobilnych podmiotów publicznych.
              </strong>
            </p>
            <p>
              Deklarację sporządzono na podstawie badania przeprowadzonego przez
              podmiot zewnętrzny: Sieć Badawcza Łukasiewicz – Poznański Instytut
              Technologiczny.
            </p>
            <p>
              Na stronie internetowej można korzystać ze standardowych skrótów
              klawiaturowych.
            </p>
            <p>
              <strong>Informacje zwrotne i dane kontaktowe</strong>
            </p>

            <p>
              W przypadku problemów z dostępnością strony internetowej
              e10.klimat.gov.pl prosimy o kontakt z Departamentem Ropy i Paliw
              Transportowych, e-mail: drp@klimat.gov.pl, tel. +48 22 3692091. Tą
              samą drogą można składać wnioski o udostępnienie informacji
              niedostępnej oraz składać żądania zapewnienia dostępności.
            </p>
            <p>
              Każdy ma prawo do wystąpienia z żądaniem zapewnienia dostępności
              cyfrowej strony internetowej, aplikacji mobilnej lub jakiegoś ich
              elementu. Można także zażądać udostępnienia informacji za pomocą
              alternatywnego sposobu dostępu, na przykład przez odczytanie
              niedostępnego cyfrowo dokumentu, opisanie zawartości filmu bez
              audiodeskrypcji, itp.
            </p>
            <p>Żądanie powinno zawierać:</p>
            <ul>
              <li>dane osoby zgłaszającej żądanie,</li>
              <li>
                wskazanie, o którą stronę internetową lub aplikację mobilną
                chodzi,
              </li>
              <li>sposób kontaktu.</li>
            </ul>
            <p>
              Jeżeli osoba żądająca zgłasza potrzebę otrzymania informacji za
              pomocą alternatywnego sposobu dostępu, powinna także określić
              dogodny dla niej sposób przedstawienia tej informacji.
            </p>
            <p>
              Podmiot publiczny powinien zrealizować żądanie niezwłocznie, nie
              później niż w ciągu 7 dni od dnia wystąpienia z żądaniem. Jeżeli
              dotrzymanie tego terminu nie jest możliwe, podmiot publiczny
              niezwłocznie informuje o tym wnoszącego żądanie, kiedy realizacja
              żądania będzie możliwa, przy czym termin ten nie może być dłuższy
              niż 2 miesiące od dnia wystąpienia z żądaniem.
            </p>
            <p>
              Jeżeli zapewnienie dostępności cyfrowej nie jest możliwe, podmiot
              publiczny może zaproponować alternatywny sposób dostępu do
              informacji.
            </p>
            <p>
              W przypadku, gdy podmiot publiczny odmówi realizacji żądania
              zapewnienia dostępności lub alternatywnego sposobu dostępu do
              informacji, wnoszący żądanie możne złożyć skargę w sprawie
              zapewniana dostępności cyfrowej strony internetowej, elementu
              strony internetowej lub aplikacji mobilnej.
            </p>
            <p>
              Po wyczerpaniu wszystkich możliwości skargę można przesłać także
              do{" "}
              <a href="https://bip.brpo.gov.pl/pl/content/zlozenie-wniosku-do-rzecznika-praw-obywatelskich">
                Rzecznika Praw Obywatelskich
              </a>
              .
            </p>
            <h4>Dostępność architektoniczna</h4>
            <p>
              Adres: Ministerstwo Klimatu i Środowiska, ul. Wawelska 52/54,
              00-922 Warszawa.
            </p>
            <p>
              Wejście główne do siedziby Ministerstwa Klimatu i Środowiska
              znajduje się od strony ul. Reja. Przed wejściem do Ministerstwa
              znajduje się podjazd dla osób poruszających się na wózku.
            </p>
            <p>
              W holu głównym, po prawej stronie, znajdują się recepcja oraz
              punkt ochrony. Recepcja jest dostosowana dla osób z
              niepełnosprawnością ruchową poprzez obniżenie jej części do
              wysokości osoby poruszającej się na wózku.
            </p>
            <p>
              Każdy gość po zarejestrowaniu się w recepcji, odbiera tzw. Kartę
              gościa (przepustkę) uprawniającą do wejścia na teren obiektu.
              Goście są wprowadzani na miejsce spotkania w asyście osoby, z
              którą byli umówieni.
            </p>
            <p>
              W holu głównym w recepcji znajduje się pętla indukcyjna dla osób
              niedosłyszących.
            </p>
            <p>
              Na terenie recepcji znajduje się wózek do dyspozycji osób o
              ograniczonej sprawności ruchowej.
            </p>
            <p>Do budynku Ministerstwa można wejść z psem asystującym.</p>
            <p>
              W holu głównym znajduje się tzw. Infokiosk, który pozwala na
              samodzielny odczyt informacji Urzędu dostępnych w formie
              elektronicznej.
            </p>
            <p>
              Korytarze i windy są przystosowane dla osób niepełnosprawnych.
              Przyciski w windach są opisane alfabetem Braille’a. Część wind
              posiada system głośnomówiący, który informuje o położeniu windy i
              jej zatrzymaniu na konkretnej kondygnacji.
            </p>
            <p>
              Tafle szklanych drzwi i innych elementów szkła
              wielkopowierzchniowego zostały oznaczone kontrastowymi pasami.
            </p>
            <p>
              Zastosowano wyróżnienie kontrastowym kolorem oraz zmienną fakturą:
            </p>
            <ul>
              <li>początku i końca biegu pochylni;</li>
              <li>
                początku i końca biegu schodów przed wejściem do budynku i
                schodów na klatkach schodowych, na korytarzach na parterze i
                piętrach na początku i końcu spadków poziomów podłogi.
              </li>
            </ul>
            <p>
              Drzwi i przejścia posiadają minimalną szerokość 90 cm, przy
              uwzględnieniu powierzchni manewrowej, umożliwiającą skorzystanie z
              przejścia osobie niepełnosprawnej.
            </p>
            <p>
              Większość wejść do pomieszczeń biurowych nie ma progów. Te, które
              je posiadają, zostały oznaczone w sposób kontrastowy, a przy
              drzwiach zastosowano piktogramy.
            </p>
            <p>
              Na każdym piętrze znajduje się minimum jedna toaleta dostosowana
              do potrzeb osób z niepełnosprawnością ruchową.
            </p>
            <p>
              Na dziedzińcu Ministerstwa oraz przed budynkiem, na ul. Reja,
              zostały wyznaczone miejsca postojowe dla osób niepełnosprawnych.
            </p>
            <p>
              Podczas załatwiania spraw w urzędzie można skorzystać z pomocy
              tłumacza języka migowego lub tłumacza-przewodnika. Wystarczy
              zgłosić taką potrzebę co najmniej na 3 dni robocze przed
              zaplanowaną wizytą w urzędzie, z wyłączeniem sytuacji nagłych.
            </p>
            <p>
              <a href="https://www.gov.pl/web/klimat/kontakt-dla-osob-z-niepelnosprawnoscia">
                Kliknij, aby przeczytać więcej o dostosowaniu funkcjonowania
                Urzędu do potrzeb osób niepełnosprawnych.
              </a>
            </p>
            <p>Budynek jest chroniony przez całą dobę.</p>
            <h4>Podstawa prawna</h4>
            <ul>
              <li>
                Rozporządzenie Rady Ministrów z 12 kwietnia 2012 r. w sprawie
                Krajowych Ram Interoperacyjności, minimalnych wymagań dla
                rejestrów publicznych i wymiany informacji w postaci
                elektronicznej oraz minimalnych wymagań dla systemów
                teleinformatycznych Dz.U. z 2012 r. nr 0, poz. 526
              </li>
              <li>
                Ustawa z 4 kwietnia 2019 r. o dostępności cyfrowej stron
                internetowych i aplikacji mobilnych podmiotów publicznych Dz.U.
                z 2019 r. poz. 848
              </li>
            </ul>
          </div>
        }
        onClose={() => {
          setIsMounted(!isMounted);
          if (!visibleAP) setVisibleAP(true);
        }}
        setShow={setVisibleAP}
      />

      <SidebarComponent
        visibleBottom={visibleBottom}
        isMountedSideBar={isMountedSideBar}
        onHide={onHide}
        setVisibleBottom={setVisibleBottom}
      />
    </div>
  );
}

export default App;
