import React, { useEffect, useRef } from "react";

const DialogComponent = ({
  visible,
  isMounted,
  headerContent,
  children,
  onClose,
  setShow,
  lastFocusedElement,
}) => {
  const mountedStyle = {
    animation: "inAnimation 100ms ease-in",
  };
  const unmountedStyle = {
    animation: "outAnimation 170ms ease-out",
    animationFillMode: "forwards",
  };

  const mountedStyleDialog = {
    animation: "inAnimationDialog 100ms ease-in",
  };
  const unmountedStyleDialog = {
    animation: "outAnimationDialog 170ms ease-out",
    animationFillMode: "forwards",
  };
  const returnRef = useRef();

  useEffect(() => {
    if (visible && returnRef.current) {
      setTimeout(() => {
        try {
          returnRef.current.focus();
        } catch {}
      }, 200);
    }
  }, [visible]);

  const handleClose = () => {
    document.getElementById(lastFocusedElement).focus();
    onClose();
  };

  return (
    visible && (
      <div
        className="dialog-background"
        style={isMounted ? mountedStyle : unmountedStyle}
        onAnimationEnd={() => {
          if (!isMounted) {
            setShow(false);
          }
        }}
      >
        <div
          className="dialog"
          style={isMounted ? mountedStyleDialog : unmountedStyleDialog}
        >
          <header className="dialog-header">{headerContent}</header>
          <div className="dialog-content">{children}</div>
          <footer className="dialog-footer">
            <button
              ref={returnRef}
              className="return-button"
              onClick={handleClose}
            >
              Zamknij
            </button>
          </footer>
        </div>
      </div>
    )
  );
};

export default DialogComponent;
