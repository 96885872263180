import React from 'react'; 
import { BrowserRouter, Route, Routes } from 'react-router-dom';

// Import your components/pages
import App from './App';
import NotFound from './NotFound';

const SiteRoutes = () => (
  <BrowserRouter basename="/">
    <Routes>
      <Route exact path="/" element={<App/>} />
      <Route path="*"element={<NotFound/>} />
    </Routes>
  </BrowserRouter>
);

export default SiteRoutes;