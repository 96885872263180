import React from 'react';
import { useEffect } from 'react';

const SidebarComponent = ({ 
  visibleBottom, 
  isMountedSideBar, 
  onHide,
  setVisibleBottom
}) => {
  const mountedStyleSideBar = {
    animation: "inAnimationSidebar 200ms ease-in",
  };
  const unmountedStyleSideBar = {
    animation: "outAnimationSidebar 170ms ease-out",
    animationFillMode: "forwards",
  };

  useEffect(() => {
    if (isMountedSideBar) {
      setTimeout(() => {
        document.getElementById("button-cookies").focus();
      }, 200);
    }
  }, [isMountedSideBar]);

  return (
    visibleBottom && (
      <div
        className="sidebar"
        style={isMountedSideBar ? mountedStyleSideBar : unmountedStyleSideBar}
        onAnimationEnd={() => {
          if (!isMountedSideBar) {
            setVisibleBottom(false);
          }
        }}
      >
        <div className="container-lg d-flex">
          <div className="sidebar-content">
            <p className="m-0" aria-live="assertive">
              W celu świadczenia usług na najwyższym poziomie stosujemy pliki
              cookies. Korzystanie z naszej witryny oznacza, że będą one
              zamieszczane w Państwa urządzeniu. W każdym momencie można
              dokonać zmiany ustawień Państwa przeglądarki.
            </p>
          </div>
          <button
            id="button-cookies"
            className="close-cookies-panel-button"
            onClick={() => onHide()}
          >
            <span className="visually-hidden">
              Zamknij informację o Cookies
            </span>
            <i className="bi bi-x-lg close-icon"></i>
          </button>
        </div>
      </div>
    )
  );
};

export default SidebarComponent;