import React from "react";
import logo from "./mkis.webp";
import e10_404 from "./e10_404.webp";
import { Link } from "react-router-dom";

const NotFound = () => (
  <div className="container-lg flexbox">
    <header className="header">
      <div>
        <div className="d-flex flex-wrap align-items-center justify-content-lg-start">
          <a
            href="https://www.gov.pl/web/klimat"
            className="d-flex align-items-center mb-2 mb-lg-0 text-dark text-decoration-none"
          >
            <img className="logo" src={logo} alt="logo" />
          </a>
        </div>
      </div>
    </header>
    <div className="d-flex">
      <div className="m-auto d-flex flex-column">
        <h1 className="title">NIE ZNALEZIONO STRONY</h1>
        <h2 className="subtitle">
          Proszę sprawdzić czy wprowadzono poprawny adres
        </h2>
        <Link to="/">
          <button className="submit-button mt-4">IDŹ DO STRONY GŁÓWNEJ</button>
        </Link>
      </div>
      <img src={e10_404} alt="img" className="right-image" />
    </div>
  </div>
);

export default NotFound;
